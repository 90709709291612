/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';
import { isEventUsinCtrlKey } from 'ngeo/utils.js';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController.js';
import stpierreBase from '../stpierremodule.js';
import { createBanDatasource } from '../base_adresse_nationale.js';
import ngeoStreetviewModule from 'ngeo/streetview/module.js';

if (!window.requestAnimationFrame) {
  alert(
    'Your browser is not supported, please update it or use another one. You will be redirected.\n\n' +
      "Votre navigateur n'est pas supporté, veuillez le mettre à jour ou en utiliser un autre. " +
      'Vous allez être redirigé.\n\n' +
      'Ihr Browser wird nicht unterstützt, bitte aktualisieren Sie ihn oder verwenden Sie einen anderen. ' +
      'Sie werden weitergeleitet.'
  );
  window.location.href = 'https://geomapfish.org/';
}

/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @ngInject
   */
  constructor($scope, $injector) {
    super($scope, $injector);

    /**
     * @type {string}
     */
    this.baseURL = $injector.get('gmfBaseUrl');
    /**
     * @type {string[]}
     */
    this.searchCoordinatesProjections = ['EPSG:2154', 'EPSG:4326'];

    // Custom datasource from BAN
    const gmfSearchOptions = $injector.get('gmfSearchOptions');
    gmfSearchOptions.datasources.push(createBanDatasource($injector, this.map));
  }

  /**
   * @param {JQueryEventObject} event keydown event.
   */
  onKeydown(event) {
    if (event && isEventUsinCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }

  documentURL(path) {
    return this.baseURL + 'documents/' + path;
  }
}
/**
 * @hidden
 */
const stpierreModule = angular.module('Appdesktop', [
  stpierreBase.name,
  gmfControllersAbstractDesktopController.name,
  ngeoStreetviewModule.name,
]);

stpierreModule.controller('DesktopController', Controller);

export default stpierreModule;
