/* helper to fetch french "Base Adresse Nationale" (BAN) and make it available
 * to the search.
 */
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import * as olProj from 'ol/proj.js';
import {appendParams as olUriAppendParams} from 'ol/uri.js';

/**
 * @return import('gmf/search/component.js').SearchComponentDatasource
 */
export function createBanDatasource(injector, map) {
  const gettextCatalog = injector.get('gettextCatalog');

  const geojsonFormat = new olFormatGeoJSON();
  return {
    datasetTitle: 'BAN',
    labelKey: 'label',
    groupValues: [],
    groupActions: [],
    projection: 'EPSG:4326',
    url: 'https://api-adresse.data.gouv.fr/search/',
    typeaheadDatasetOptions: {
      templates: /* Twitter.Typeahead.Templates */ ({
        header: () => {
          const header = gettextCatalog.getString("Base Adresse Nationale");
          return `<div class="gmf-search-header">${header}</div>`;
        },
      }),
    },
    bloodhoundOptions: {
      remote: {
        prepare: (query, settings) => {
          const coordinates = olProj.transform(
              map.getView().getCenter(),
              map.getView().getProjection(),
              olProj.get("EPSG:4326"),
          );
          settings.url = olUriAppendParams(settings.url, {
            q: query,
            limit: 5,
            lat: coordinates[1],
            lon: coordinates[0],
          });
          return settings;
        },
        transform(parsedResponse) {
          /** @type {GeoJSON.FeatureCollection} */
          const featureCollection = parsedResponse;
          return geojsonFormat.readFeatures(featureCollection, {
            featureProjection: map.getView().getProjection(),
            dataProjection: olProj.get("EPSG:4326"),
          });
        },
      },
    },
  }
}
